<template>
    <div>

        <h1> {{ this.piece.name }}</h1>

        
<!-- 
        <div>

            chart segun operaciones

            {{ this.piece.times }}
        </div>

        <div class="grid">
            <div class="col-3">

                <Chart type="pie" :data="chartData" :options="lightOptions" />
            </div>
            <div class="col-5">

               <h6>
                   Mejor tiempo segun operario
               </h6>

               {{ operatorsData }}

               <Chart type="bar" :data="operatorsData" :options="lightOptions" />
            </div>
            </div>  

            <hr /> -->
<!-- 
<pre>
    {{ testData }}
</pre> -->

<div class="card">
            <h5>Tiempos por operario</h5>
            <DataTable :value="testData" rowGroupMode="subheader" groupRowsBy="operator_name"
                sortMode="single" sortField="operator_name" :sortOrder="2" scrollable scrollHeight="400px">
                <Column field="operator_name" header="Operador"></Column>
                <Column field="operation_name" header="Operación" style="min-width:200px"></Column>

                
                <Column field="sys_time" header="Tiempo" style="min-width:100px">
                    <template #body="slotProps">
                        <span :class="'customer-badge status-' + slotProps.data.status">{{this._readableTimeFromSeconds(slotProps.data.sys_time)}}</span>
                    </template>
                </Column>
                <Column field="created_at" header="Fecha" style="min-width:200px"></Column>
                <template #groupheader="slotProps">
                    <!-- <img :alt="slotProps.data.operator_name" src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" 
                    width="32" style="vertical-align: middle" /> -->
                    <Avatar icon="pi pi-user" class="mr-2"  style="background-color:#2196F3; color: #ffffff" shape="circle" />
                    <h6 class="image-text" style="text-transform: uppercase">{{slotProps.data.operator_name}}</h6>
                </template>
            </DataTable>
        </div>


    </div>
</template>

<script>
import  CrudService from '../../services/crud.service.js'

    export default {
        data() {
            return {
                testData: null,
                operatorsData: {
                labels: ['A','B','C'],
                datasets: [
                    {
                        data: [300, 50, 100],
                        backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
                        hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"]
                    }
                ]
            },
                chartData: {
                labels: ['A','B','C'],
                datasets: [
                    {
                        data: [300, 50, 100],
                        backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
                        hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"]
                    }
                ]
            },
            lightOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                }
            },
                piece: {
                    name: '',
                    produced: 54,
                    selled: 12,
                    description: '',
                    image: '',
                    price: '',
                    category: '',
                    created_at: '',
                    updated_at: '',
                    history: [
                    ]
                }
            }
        },
        mounted() {
            this.getPiece();
        },
        methods: {
            _readableTimeFromSeconds(seconds) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds - hours * 3600) / 60);
      let secs = seconds - hours * 3600 - minutes * 60;
      return `${hours}h ${minutes}m ${secs.toFixed(0)}s`;
    },
                toArs(value) {
 
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    },
            getPiece() {
                    CrudService.getCRUD('api/pieza_times/' + this.$route.params.id).then(response => {

                        //this.piece = response;
                        // merge response with piece

                        this.piece = Object.assign(this.piece, response.piece);

                        this.chartData.labels = response.operations.labels;
                        this.chartData.datasets[0].data = response.operations.datasets;

                        this.operatorsData.labels = response.operators.labels;
                        this.operatorsData.datasets[0].data = response.operators.datasets;

                        this.testData = response.testData;

                    });

            }
        }
    }

</script>
<style lang="scss" scoped>
.p-rowgroup-footer td {
    font-weight: 700;
}

::v-deep(.p-rowgroup-header) {
    span {
        font-weight: 700;
    }

    .p-row-toggler {
        vertical-align: middle;
        margin-right: .25rem;
    }
}
</style>   